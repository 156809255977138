.nav_style {
  color: #000000;
  font-weight: 300 !important;
  @apply transition;
  @apply ease-in-out;
  @apply delay-150;
  margin: 0px 1rem;
  display: inline-block;
  position: relative;
}

.nav_style_mobile {
  color: #ffffff;
  font-weight: 300 !important;
  @apply transition;
  @apply ease-in-out;
  @apply delay-150;
  margin: 0.5rem 0rem;
  display: inline-block;
  position: relative;
}

.nav_style:hover {
  color: #1F43DB;
}

.nav-bar-floated {
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(6px);
}
