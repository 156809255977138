.section-one {
  background-image: url("../assets/imgs/home-bg-img.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transition: all 200ms ease-in-out;
  cursor: pointer;
  height: 80vh;
}

/* For smaller devices */
@media (max-width: 768px) {
  .section-one {
    height: 60vh; 
    background-size: contain; 
    background-position: center;
  }
}

/* For extra small devices */
@media (max-width: 480px) {
  .section-one {
    height: 50vh; 
    background-size: cover; 
    background-position: center;
  }
}


.section-two {
  background: transparent
    linear-gradient(237deg, #1f43db 0%, #1f43db 45%, #001faa 100%) 0% 0%
    no-repeat padding-box;
}

.section-four {
  background: transparent
    linear-gradient(46deg, #1f43db 0%, #1f43db 18%, #001faa 100%) 0% 0%
    no-repeat padding-box;
  margin-top: 6rem;
}

.section-five {
  background: transparent linear-gradient(119deg, #1d1d1b 0%, #3f3f3a 100%) 0%
    0% no-repeat padding-box;
}

.section-six {
  background: transparent linear-gradient(119deg, #c8d0f7 0%, #ffffff 100%) 0%
    0% no-repeat padding-box;
}

.section-seven {
  background: transparent linear-gradient(228deg, #1d1d1b 0%, #3f3f3a 100%) 0%
    0% no-repeat padding-box;
}

.section-eight {
  background: transparent linear-gradient(228deg, #c1cbf6 0%, #c0e7001a 100%) 0%
    0% no-repeat padding-box;
}

.section-nine {
  background: #ffffff;
}

.why-choose {
  position: relative;
}

.why-choose img {
  display: none;
}

.why-choose:hover {
  background: #000000;
  transition: all 200ms ease-in-out;
  cursor: pointer;
}

.why-choose:hover p {
  color: #ffffff;
}

.why-choose:hover img {
  display: block;
  transition: all 200ms ease-in-out;
}

.ent-light {
  transition: all 500ms ease-in-out;
  visibility: visible;
  opacity: 1;
}

.ent-blue {
  // transition: all 500ms ease-in-out;
  // display: none;

  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
}

.section-one:hover .ent-light {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
}

.section-one:hover .ent-blue {
  transition: all 500ms ease-in-out;
  visibility: visible;
  opacity: 1;
}

.linked-in {
  position: absolute;
  height: 32px;
  width: 32px;
  background: #EAEAEA;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: bold;
  border-radius: 50%;
  bottom: -10%;
  transition: all 100ms ease-in-out;
  padding-top: 2px;
  margin: 0px auto;
  left: 0;
  right: 0;
}

.linked-in:hover {
  background: #1F43DB;
  color: #ffffff;
}