.arrow-button {
  display: flex;
  color: #ffffff;
  background-color: transparent;
  border: 1.5px solid #ffffff;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  transition: all 0.3s ease;
  font-weight: 300;
  cursor: pointer;
  align-items: center;
}

.arrow-button:hover {
  width: 100%;
  border-radius: 32px;
  justify-content: left;
}

.arrow-button .absolute {
  display: flex;
  color: #ffffff;
  transition: all 0.3s ease;
  font-weight: 300;
  align-items: center;
  font-size: 14px;
  left: 36%;
}

.arrow-button:hover .absolute {
  color: #ffffff;
  left: 16%;
}

.arrow-button > .arrow {
  width: 10px;
  height: 10px;
  border-right: 1.5px solid #ffffff;
  border-bottom: 1.5px solid #ffffff;
  position: relative;
  transform: rotate(-45deg);
  margin: 0 10px;
  transition: all 0.3s ease;
}

.arrow-button:hover > .arrow {
  transform: rotate(-45deg) translate(4rem, 4rem);
  border-color: #ffffff;
  margin-left: 2rem;
}

.arrow-button:hover {
  background-color: #000000;
  color: #fff;
  border: none;
}

.arrow-button-black {
  display: flex;
  color: #000000;
  background-color: transparent;
  border: 1.5px solid #000000;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  transition: all 0.3s ease;
  font-weight: 300;
  cursor: pointer;
  align-items: center;
}

.arrow-button-black:hover {
  width: 100%;
  border-radius: 32px;
  justify-content: left;
}

.arrow-button-black .absolute {
  display: flex;
  color: #000000;
  transition: all 0.3s ease;
  font-weight: 300;
  align-items: center;
  font-size: 14px;
  left: 36%;
}

.arrow-button-black:hover .absolute {
  color: #ffffff;
  left: 16%;
}

.arrow-button-black > .arrow {
  width: 10px;
  height: 10px;
  border-right: 1.5px solid #000000;
  border-bottom: 1.5px solid #000000;
  position: relative;
  transform: rotate(-45deg);
  margin: 0 10px;
  transition: all 0.3s ease;
}

.arrow-button-black:hover > .arrow {
  transform: rotate(-45deg) translate(4rem, 4rem);
  border-color: #ffffff;
  margin-left: 2rem;
}

.arrow-button-black:hover {
  background-color: #1F43DB;
  color: #fff;
  border: none;
}

.arrow-button-white {
  display: flex;
  color: #ffffff;
  background-color: transparent;
  border: 1.5px solid #ffffff;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  transition: all 0.3s ease;
  font-weight: 300;
  cursor: pointer;
  align-items: center;
}

.arrow-button-white:hover {
  width: 100%;
  border-radius: 32px;
  justify-content: left;
}

.arrow-button-white .absolute {
  display: flex;
  color: #ffffff;
  transition: all 0.3s ease;
  font-weight: 300;
  align-items: center;
  font-size: 14px;
  left: 36%;
}

.arrow-button-white:hover .absolute {
  color: #ffffff;
  left: 16%;
}

.arrow-button-white > .arrow {
  width: 10px;
  height: 10px;
  border-right: 1.5px solid #ffffff;
  border-bottom: 1.5px solid #ffffff;
  position: relative;
  transform: rotate(-45deg);
  margin: 0 10px;
  transition: all 0.3s ease;
}

.arrow-button-white:hover > .arrow {
  transform: rotate(-45deg) translate(4rem, 4rem);
  border-color: #ffffff;
  margin-left: 2rem;
}

.arrow-button-white:hover {
  background-color: #1F43DB;
  color: #fff;
  border: none;
}

.static-button-primary {
  font-size: 14px;
  font-weight: 300;
  color: #ffffff;
  border-radius: 21px;
  background: #1d1d1b 0% 0% no-repeat padding-box;
  padding: 8px 20px;
  display: flex;
  align-items: center;
  transition: all 200ms ease-in-out;
}

.static-button-primary img {
  margin-left: 10px;
  height: 14px;
}

.static-button-primary:hover {
  background: #1f43db 0% 0% no-repeat padding-box;
}

.static-button-secondary {
  font-size: 14px;
  font-weight: 300;
  color: #ffffff;
  border-radius: 21px;
  background: #1f43db 0% 0% no-repeat padding-box;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  transition: all 200ms ease-in-out;
}

.static-button-secondary img {
  margin-left: 10px;
  height: 14px;
}
