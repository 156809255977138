.footer-links {
  @apply leading-9;
  @apply text-secondary-500;
  @apply duration-300;
  @apply font-light;
}

.footer-links:hover {
  @apply text-primary-500;
}

.footer-icon {
  @apply flex;
  @apply justify-center;
  @apply items-center;
  @apply w-7;
  @apply h-7;
  @apply duration-300;
}

input {
  padding: 10px;
  color: #ffffff;
  font-weight: 300;
}

input:focus {
  outline: none;
}

input::placeholder {
  color: #ffffff;
}