* {
  @apply text-secondary-500;
}

p {
  @apply text-secondary-500;
}

.container {
  width: 100%;
  @apply mx-auto;
  @apply px-5;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

.big-shadow {
  box-shadow: 0px 3.46736px 13.0026px rgba(44, 56, 145, 0.38);
}

.ant-form-item {
  @apply m-0;
}

.CookieConsent {
  bottom: 3rem !important;
  border-radius: 16px;
  align-items: center !important;
}

.consent-button {
  display: flex;
  color: #ffffff;
  background-color: transparent;
  border: 1.5px solid #ffffff;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  transition: all 0.3s ease;
  font-weight: 300;
  cursor: pointer;
  align-items: center;
}

.consent-button:hover {
  width: 100%;
  border-radius: 32px;
  justify-content: left;
}

.consent-button .absolute {
  display: flex;
  color: #ffffff;
  transition: all 0.3s ease;
  font-weight: 300;
  align-items: center;
  font-size: 14px;
  left: 36%;
  font-weight: 600;
}

.consent-button:hover .absolute {
  color: #1f43db;
  font-weight: 600;
  left: 16%;
}

.consent-button > .arrow {
  width: 10px;
  height: 10px;
  border-right: 1.5px solid #ffffff;
  border-bottom: 1.5px solid #ffffff;
  position: relative;
  transform: rotate(-45deg);
  margin: 0 10px;
  transition: all 0.3s ease;
}

.consent-button:hover > .arrow {
  transform: rotate(-45deg) translate(4rem, 4rem);
  border-color: #1f43db;
  margin-left: 2rem;
}

.consent-button:hover {
  background-color: #ffffff;
  color: #1f43db;
  border: none;
}

.cookie-modal .ant-modal-content {
  padding: 38px;
  background: #1f43db;
  color: #ffffff;
}

.form-group {
  display: block;
  margin-bottom: 15px;
}

.form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.form-group label {
  position: relative;
  cursor: pointer;
}

.form-group label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #80ACC7;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
  border-radius: 4px;
}

.form-group input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: -2px;
  left: 9px;
  width: 6px;
  height: 14px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.form-group input:checked + label:before {
  background: #9DADF1;
}
